<template>
    <div class="planned-activities">
        <h6 class="section-title">
            PLANNED ACTIVITIES
        </h6>
        <div class="planned-activities-container">
            <template v-for="activity in plannedActivities">
                <timeline-activity :key="Number(activity.id)" :activity="activity" />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "PlannedActivities",
    components: {
        TimelineActivity: () => import(/* webpackChunkName: "timeline-activity" */ "../timeline-activity/")
    },
    props: {
        plannedActivities: {
            type: Array,
            required: true
        }
    }
}
</script>

<style lang="scss">
.planned-activities {
    margin-bottom: 40px;

    .section-title {
        font-family: 'Lato', sans-serif;
        color: #9594AF;
        font-size: 16px;
        letter-spacing: 3px;
        margin-bottom: 25px;
    }

    .planned-activities-container {
        .timeline-activity {
            &:first-child {
                .activity-icon-container {
                    &::before {
                        display: none;
                    }
                }
            }

            &:last-child {
                .activity-icon-container {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
